import { getMode } from "./helpers/themeMode";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
);
const color = getMode() + ".blue";

export const render = () => {
  import(`./assets/scss/themes/${color}.scss`).then((x) => {
    require("./AppRender");
  });
};

render();
