import ReactDOM from "react-dom";
import App from "./App";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./antd.css";
import { LoadingOverlay } from "@mantine/core";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  detection: {
    order: ["navigator", "localStorage", "cookie", "htmlTag"],
    caches: ["localStorage", "cookie"],
  },
  fallbackLng: "en",
  resources: {
    en: {
      translation: require("./i18n/locales/en/translations.json"),
    },
    fr: {
      translation: require("./i18n/locales/fr/translations.json"),
    },
    // Add more language resources as needed
  },
});

/**
 * render app
 * suspense needed for lazy loading
 * and Provider needed for store data for example save data from information api dashboard
 * **/
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Suspense fallback={<LoadingOverlay visible={true} />}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </Suspense>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
