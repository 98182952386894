import React from "react";

/**
 * change them to light or dark
 * */
const switchMode = (mode: "dark" | "light") => {
    localStorage.setItem("mode-theme", mode);
    window.location.reload();
}

const getMode = () => {
    const modeTheme = localStorage.getItem("mode-theme");
    if (modeTheme !== undefined && modeTheme !== null)
        return modeTheme;

    return "dark";
}

const toggleMode = () => {
    if (getMode() === "dark")
        switchMode("light");
    else
        switchMode("dark");
}

const useMode = () => {
    const [state, setState] = React.useState(getMode());
    const handleChangeLocalStorage = () => {
        setState(getMode());
    }

    React.useEffect(() => {
        window.addEventListener("storage", handleChangeLocalStorage);
        return window.removeEventListener("storage", handleChangeLocalStorage);
    }, [])

    return state;
}

export {switchMode, getMode, toggleMode, useMode};