import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useAuth from "../controllers/account/useAuth";

const App = React.lazy(() => import(/* webpackChunkName: "viwes" */ "./app"));
const Installer = React.lazy(
  () => import(/* webpackChunkName: "viwes" */ "./installer")
);
const Admin = React.lazy(
  () => import(/* webpackChunkName: "viwes" */ "./admin")
);
const Login = React.lazy(
  () => import(/* webpackChunkName: "viwes" */ "./user")
);

/**
 * authorize user if authorized we show App and if don't we show login page
 * **/

const View = () => {
  const user = useSelector((state: any) => state.user);
  return (
    <>
      {user?.auth_key !== "" && user?.auth_key !== null ? (
        <AccessUser />
      ) : (
        <Login />
      )}
    </>
  );
};

const AccessUser = () => {
  const { role, jwtBody } = useAuth();

  useEffect(() => {
    if (jwtBody === undefined) {
      localStorage.removeItem("AccessToken");
    }
  }, [jwtBody]);

  return (
    <>
      {role === "admin" && <Admin />}
      {role === "installer" && <Installer />}
      {role === "customer" && <App />}
    </>
  );
};

export default View;
