import { useLocalStorage } from "@mantine/hooks";

type ColorScheme = "light" | "dark";

export function useColorScheme(): [ColorScheme, (value?: ColorScheme) => void] {
  const [colorScheme, setColorScheme] = useLocalStorage<"dark" | "light">({
    key: "color-scheme",
    defaultValue: "light",
  });

  const toggleColorScheme = () => {
    setColorScheme(colorScheme === "dark" ? "light" : "dark");
  };

  return [colorScheme, toggleColorScheme];
}
export default useColorScheme;
