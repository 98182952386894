import { combineReducers } from "redux";
import {ReducerDashboard} from "./dashboard.reducer";
import { ReducerSidebar } from "./sidebar.reducer";
import {ReducerUser} from "./user.reducer";

const Reducer = combineReducers({
  dashboard: ReducerDashboard,
  sidebar: ReducerSidebar,
  user: ReducerUser
});


export default Reducer;