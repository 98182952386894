const typeUser = {
  LOGIN: "user/login",
  LOGOUT: "user/logout",
};
const initialState: any = {
  auth_key: null,
  user_info: null,
};

/**
 * user auth key save and load here
 * **/
const ReducerUser = (store = initialState, action: any = {}) => {
  switch (action.type) {
    case typeUser.LOGIN:
      localStorage.setItem("auth_key", action.payload);
      localStorage.setItem("display_name", action.userPayload.user.displayName);
      localStorage.setItem("email", action.userPayload.user.email);

      return { auth_key: action.payload, user_info: action.userPayload };

    case typeUser.LOGOUT:
      localStorage.setItem("auth_key", "");
      return { auth_key: null };

    default:
      if (store.auth_key === null) {
        if (
          localStorage.getItem("auth_key") !== "" &&
          localStorage.getItem("auth_key") !== undefined
        ) {
          store.auth_key = localStorage.getItem("auth_key");
        }
      }
      return store;
  }
};

export { ReducerUser, typeUser };
