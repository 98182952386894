import { ColorSchemeProvider, MantineProvider } from "@mantine/core";

import { QueryClient, QueryClientProvider } from "react-query";
import { NotificationsProvider } from "@mantine/notifications";
import View from "./view";
import useColorScheme from "./controllers/Global/useColorScheme";

const App = () => {
  const [colorScheme, toggleColorScheme] = useColorScheme();
  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <NotificationsProvider>
          <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
          >
            <MantineProvider
              theme={{ colorScheme, fontFamily: "Quicksand" }}
              withGlobalStyles
              withNormalizeCSS
            >
              <View />
            </MantineProvider>
          </ColorSchemeProvider>
        </NotificationsProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
