import { useState, useEffect } from "react";
import parseJwt from "../../helpers/parseJWT";

function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState("");

  const customerId = sessionStorage.getItem("customerId");
  const customerRole = sessionStorage.getItem("customerRole");
  const localRole = localStorage.getItem("customerRole");

  let token = localStorage.getItem("auth_key");
  token = token === null ? "" : token;
  const jwtBody = parseJwt(token);

  useEffect(() => {
    if (customerRole === undefined) {
      localStorage.removeItem("auth_key");
      window.location.reload();
      return;
    }

    const isCustomer =
      jwtBody["cognito:groups"][0] === "customer" ||
      customerRole === "customer";

    const isInstaller =
      ((customerId === undefined || customerId === null) &&
        jwtBody["cognito:groups"][0] === "installer") ||
      customerRole === "installer";

    const isAdmin =
      ((customerId === undefined || customerId === null) &&
        jwtBody["cognito:groups"][0] === "admin") ||
      (localRole === "admin" && !isInstaller && !isCustomer);

    setIsAuthenticated(isInstaller || isCustomer || isAdmin);
    setRole(isInstaller ? "installer" : isCustomer ? "customer" : "admin");
  }, [customerId, customerRole, jwtBody, localRole]);

  return { isAuthenticated, role, jwtBody };
}

export default useAuth;
