const typeSidebar = {
  setActive: "sidebar/setActive",
};
const initialState = 0;
/**
 * sidebar
 * **/
const ReducerSidebar = (store = initialState, action: any = {}) => {
  switch (action.type) {
    case typeSidebar.setActive:
      return action.payload;
    default:
      return store;
  }
};

export { ReducerSidebar, typeSidebar };
