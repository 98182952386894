const typeDashboard = {
  LOAD: "dashboard/load",
  CREATE: "dashboard/create",
  UPDATE: "dashboard/update",
  DELETE: "dashboard/delete",
};
const initialState = {
  profile: {},
  stations: [],
};
/**
 * dashboard information api load here
 * **/
const ReducerDashboard = (store = initialState, action: any = {}) => {
  switch (action.type) {
    case typeDashboard.CREATE:
      return { ...action.payload };

    case typeDashboard.UPDATE:
      return { ...store, ...action.payload };

    case typeDashboard.DELETE:
      return store;

    default:
      return store;
  }
};


export {ReducerDashboard,typeDashboard};